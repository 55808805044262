

 .align-items-center {
  display: flex !important; 
  align-items: center;  /*Aligns vertically center */
  justify-content: center; /*Aligns horizontally center */
  height:100%;
}

.loading{
  width:3em;
  height:3em;
}

.hover_underline:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.row-spacer {  
  height:1px;
}


.container{
  margin-top:100px;
}

 